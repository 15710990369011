/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

// Styling
import './style.sass';

// BS JS
import Collapse from 'bootstrap/js/dist/collapse';
import Carousel from 'bootstrap/js/dist/carousel';
import Modal from 'bootstrap/js/dist/modal';
import Alert from 'bootstrap/js/dist/alert';
import Button from 'bootstrap/js/dist/button';
import SmoothScroll from 'smooth-scroll';
import axios from 'axios';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

const apiUrl = process.env.API_URL;
const proUrl = process.env.PRO_URL;
const partnerUrl = process.env.PARTNER_URL;

document.addEventListener('alpine:init', () => {
  Alpine.bind('NextStep', () => ({
    type: 'submit',

    '@click.prevent'() {
      const form = this.$el.closest('form');

      if (!form.checkValidity()) {
        form.reportValidity();
        this.stepOneCompleted = false;
      } else {
        this.step = Number(this.step) + 1;
        this.stepOneCompleted = true;
      }
    },
  }));

  Alpine.bind('SubmitData', (stripePaymentLink) => ({
    type: 'submit',

    async '@click.prevent'() {

      const form = this.$el.closest('form');

      if (!form.checkValidity()) {
        form.reportValidity();
      } else {
        this.shouldDisable = true;

        const signupObj = {};

        // Step 1 data
        const SignUpStep1 = document.getElementById('SignUpStep1');

        const stepOneData = new FormData(SignUpStep1);

        signupObj.gender = stepOneData.get('gender');

        signupObj.pregnant =
          stepOneData.get('status') === 'pregnant' ? 'Yes' : 'No';
        signupObj.breastfeeding =
          stepOneData.get('status') === 'breastfeeding' ? 'Yes' : 'No';

        if (stepOneData.get('status') === 'pregnant_breastfeeding') {
          signupObj.pregnant = 'Yes';
          signupObj.breastfeeding = 'Yes';
        }

        const dob = new Date(
          stepOneData.get('dob-year'),
          stepOneData.get('dob-month') - 1,
          stepOneData.get('dob-day')
        );
        signupObj.dob = dob.toISOString();

        signupObj.widgets = { meassure: stepOneData.get('measures') };

        // Imperial
        if (stepOneData.get('measures') === 'imperial') {
          const heightInch = Number(
            stepOneData.get('height-ft') * 12 +
              Number(stepOneData.get('height-in'))
          ).toFixed(2);
          const height = 2.54 * heightInch;
          const weight = stepOneData.get('weight-lb');
          const desired_weight = stepOneData.get('weight-goal-lb');
          signupObj.height = { units: 'ft', value: height.toString() };
          signupObj.weight = {
            units: 'lb',
            value: Number(weight).toFixed(3).toString(),
          };
          signupObj.desired_weight = {
            units: 'lb',
            value: Number(desired_weight).toFixed(3).toString(),
          };
        }
        // Metric
        if (stepOneData.get('measures') === 'metric') {
          signupObj.height = {
            units: 'cm',
            value: stepOneData.get('height-cm'),
          };
          signupObj.weight = {
            units: 'kg',
            value: stepOneData.get('weight-kg'),
          };
          signupObj.desired_weight = {
            units: 'kg',
            value: stepOneData.get('weight-goal-kg'),
          };
        }

        signupObj.activity = stepOneData.get('level-of-activity');

        // Step 2 data
        const stepTwoData = new FormData(form);

        const fullName = stepTwoData.get('full-name');
        const nameArray = fullName.split(' ');

        let [first_name, last_name] = nameArray;

        signupObj.first_name = first_name;
        signupObj.last_name = last_name;
        signupObj.email = stepTwoData.get('email');
        signupObj.password = stepTwoData.get('new-password');

        try {
          const createdUser = await axios.post(`${apiUrl}/api/v1/users`, {
            email: signupObj.email,
            password: signupObj.password,
            login_type: 0,
            user_role: 'user',
            is_new: true,
          });

          if (createdUser.data.error) {
            this.shouldDisable = false;
            this.error = createdUser.data.error.message;
          }

          const userAuth = await axios.post(
            `${apiUrl}/api/v1/auth/local`,
            {
              email: signupObj.email,
              password: signupObj.password,
              user_role: 'user',
            },
            {
              headers: { 'Access-Control-Allow-Origin': '*' },
            }
          );

          await axios.put(
            `${apiUrl}/api/v1/users/${userAuth.data._id}`,
            {
              breastfeeding: signupObj.breastfeeding,
              desired_weight: signupObj.desired_weight,
              dob: signupObj.dob,
              fb_user: false,
              first_name: signupObj.first_name,
              gender: signupObj.gender,
              height: signupObj.height,
              last_name: signupObj.last_name,
              pregnant: signupObj.pregnant,
              weight: signupObj.weight,
              widgets: signupObj.widgets,
              profileFilled: true,
              is_active: true,
              user_name: (
                signupObj.first_name + Math.floor(10000 + Math.random() * 90000)
              ).toLowerCase(),
            },
            { headers: { Authorization: `Bearer ${userAuth.data.token}` } }
          );
          const patientPreferencesObj = createdUser.data.patientPreferences;

          patientPreferencesObj.activity = signupObj.activity;
          patientPreferencesObj.maintain = '';
          patientPreferencesObj.weather = '';
          patientPreferencesObj.workout = '';
          patientPreferencesObj.app_flag = true;

          await axios.post(
            `${apiUrl}/api/v1/patient_preferences/${createdUser.data.patientPreferences._id}`,
            patientPreferencesObj
          );

          const finalizedUser = await axios.get(
            `${apiUrl}/api/v1/users/${createdUser.data._id}`,
            {
              headers: { Authorization: `Bearer ${userAuth.data.token}` },
            }
          );

          this.userEmail = finalizedUser.data.email;

          window.location.href = stripePaymentLink + `?prefilled_email=${encodeURIComponent(this.userEmail)}`

        } catch (error) {
          // console.log('catch error ============>', error);
        }
      }
    },

    ':disabled'() {
      return this.shouldDisable;
    },
  }));
});

Alpine.start();

const scroll = new SmoothScroll('a[href*="#"]', {
  // speed: 500,
	// speedAsDuration: true,
  durationMax: 500,
	durationMin: 250,
});

// Navbar toggle
document.addEventListener('click', () => {
  const theNavbar = document.getElementById('theNavbar');

  let collapsibleNavbar = null;
  if (!collapsibleNavbar) collapsibleNavbar = Collapse.getInstance(theNavbar);

  const theNavbarIsOpen = theNavbar?.classList.contains('show');

  if (theNavbarIsOpen === true) {
    collapsibleNavbar.hide();
  }
});

// Pass toggle btns
const togglePassBtns = document.querySelectorAll('.toggle-pass');

togglePassBtns.forEach((togglePassBtn) =>
  togglePassBtn.addEventListener('click', (e) => {
    const passField = e.target.parentElement.querySelector('.pass-input');
    const type =
      passField.getAttribute('type') === 'password' ? 'text' : 'password';
    passField.setAttribute('type', type);
    e.target.classList.toggle('pass-shown');
  })
);

// Alerts for auth with BS5
const alert = (wrapperId, message = false, type = false) => {
  const wrapper = document.getElementById(wrapperId);
  if (!message || !type) {
    wrapper.innerHTML = '';
    return;
  }
  wrapper.innerHTML = `<div class="alert alert-${type} alert-dismissible" role="alert">${message}<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
};

// Professionals sign up
const fullSignUpForm = document.getElementById('fullSignUp');
const signUpModalBox = document.getElementById('signUpModal');

if (signUpModalBox) {
  const signUpModalOn = new Modal(signUpModalBox, {});

  const signUpModalForReset = signUpModalBox;
  signUpModalForReset.addEventListener('hidden.bs.modal', () => {
    fullSignUpForm.reset();

    // eslint-disable-next-line no-use-before-define
    alert('signupFormAlert');
  });

  fullSignUpForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const fullSignUpFormData = new FormData(fullSignUpForm);
    const name = fullSignUpFormData.get('full-name');
    const email = fullSignUpFormData.get('email');
    const password = fullSignUpFormData.get('new-password');
    const partner = fullSignUpFormData.get('partner');

    axios
      .post(`${apiUrl}/api/v1/users`, {
        email,
        password,
        first_name: name,
        is_nutritionist: true,
        is_partner: partner == 'partner',
      })
      .then((usersResponse) => {
        if (usersResponse.status === 201) {
          axios
            .post(`${apiUrl}/api/v1/auth/local`, {
              email,
              password,
              user_role: 'expert',
            })
            .then((authResponse) => {
              const proToken = authResponse.data.token;
              const isPartner = authResponse.data.is_partner;

              signUpModalOn.hide();
              fullSignUpForm.reset();

              if (isPartner) window.location.href = `${partnerUrl}/jwtlogin?mode=signup&token=${proToken}`;
              else window.location.href = `${proUrl}/jwtlogin?mode=signup&token=${proToken}`;
            })
            .catch(() => {
              alert(
                'signupFormAlert',
                'Your account was successfully created, but failed to login. Please login with your email and password in login form',
                'warning'
              );
            });
        } else {
          // eslint-disable-next-line no-lonely-if
          if (usersResponse.data && usersResponse.data.error) {
            if (usersResponse.data.error.code === 'DUP_ENTRY') {
              alert(
                'signupFormAlert',
                'A user for this email already exists',
                'danger'
              );
            } else {
              alert('signupFormAlert', usersResponse.data.error.message, 'danger');
            }
          } else {
            alert(
              'signupFormAlert',
              'Unknown error, please try again',
              'danger'
            );
          }
        }
      })
      .catch(() => {
        alert('signupFormAlert', 'Unknown error, please try again', 'danger');
      });
  });
}

// Auth login
const loginForm = document.getElementById('loginForm');
const loginModalBox = document.getElementById('loginModal');

if (loginModalBox) {
  const loginModalOn = new Modal(loginModalBox, {});

  const loginModalForReset = loginModalBox;
  loginModalForReset.addEventListener('hidden.bs.modal', () => {
    loginForm.reset();

    alert('loginFormAlert');
  });

  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const loginFormData = new FormData(loginForm);
    const email = loginFormData.get('email');
    const password = loginFormData.get('current-password');

    axios
      .post(`${apiUrl}/api/v1/auth/local`, {
        email,
        password,
        user_role: 'expert',
      })
      .then((response) => {
        const proToken = response.data.token;
        const isPartner = response.data.is_partner;
        loginModalOn.hide();
        loginForm.reset();
        if (isPartner) window.location.href = `${partnerUrl}/jwtlogin?mode=login&token=${proToken}`;
        else window.location.href = `${proUrl}/jwtlogin?mode=login&token=${proToken}`;
      })
      .catch((error) => {
        let message;
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 401:
              message = 'Invalid email, role or password';
              break;
            default:
              message = error.message;
              break;
          }
        }

        alert('loginFormAlert', message, 'danger');
      });
  });
}

// Auth forgot pass
const forgotForm = document.getElementById('forgotForm');
const forgotPassModalBox = document.getElementById('forgotPassModal');

if (forgotPassModalBox) {
  // eslint-disable-next-line no-unused-vars
  const forgotPassModalOn = new Modal(forgotPassModalBox, {});

  const forgotPassModalForReset = forgotPassModalBox;
  forgotPassModalForReset.addEventListener('hidden.bs.modal', () => {
    forgotForm.reset();

    alert('forgotFormAlert');
  });

  forgotForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('forgotPassEmailInput').value;

    axios
      .post(`${apiUrl}/api/v1/recovery/password`, {
        email_user: email,
      })
      .then((response) => {
        if (response.status === 200) {
          alert(
            'forgotFormAlert',
            'Email has been sent, please check your inbox!',
            'success'
          );
        }
      })
      .catch((error) => {
        let message;
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 500:
              message = 'Internal Server Error';
              break;
            default:
              message = error.message;
              break;
          }
        }

        alert('forgotFormAlert', message, 'danger');
      });
  });
}

// Contact us
const contactUsForm = document.getElementById('contactUs');
const contactUsModalBox = document.getElementById('contactUsModal');

// reCaptacha callbacks
window.verifyRecaptchaCallback = (response) => {
  document.querySelector('input[data-recaptcha]').value = response;
}

window.expiredRecaptchaCallback = () => {
  document.querySelector('input[data-recaptcha]').value = '';
}

if (contactUsModalBox) {
  const contactUsModalOn = new Modal(contactUsModalBox, {});

  const contactUsModalForReset = contactUsModalBox;
  contactUsModalForReset.addEventListener('hidden.bs.modal', () => {
    contactUsForm.reset();

    alert('contactUsFormAlert');
  });

  contactUsForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const contactUsFormData = new FormData(contactUsForm);
    const name = contactUsFormData.get('name');
    const email = contactUsFormData.get('email');
    const message = contactUsFormData.get('message');
    const reCaptcha = contactUsFormData.get('g-recaptcha-response')

    // Missing recaptcha
    if (reCaptcha == '') {
      alert(
        'contactUsFormAlert',
        'reCAPTCHA is missing, try again!',
        'danger'
      );

      return;
    }

    axios
      .post(`${apiUrl}/api/v1/email/contact-us`, {
        name: name,
        email: email,
        message: message,
        reCaptcha: reCaptcha
      })
      .then((response) => {
        if (response.status === 200) {
          alert(
            'contactUsFormAlert',
            `Thanks for you contact request, we'll get back to you shortly!`,
            'success'
          );

          contactUsForm.reset();
        }
      })
      .catch((error) => {
        let message;
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 500:
              message = 'Internal Server Error';
              break;
            default:
              message = error.message;
              break;
          }
        }

        alert('contactUsFormAlert', message, 'danger');
      });

    grecaptcha.reset();
  });
}

// Hiring
const hiringForm = document.getElementById('hiringForm');
const hiringModalBox = document.getElementById('hiringModal');

if (hiringModalBox) {
  const hiringFormOn = new Modal(hiringModalBox, {});

  const hiringModalBoxForReset = hiringModalBox;
  hiringModalBoxForReset.addEventListener('hidden.bs.modal', () => {
    hiringForm.reset();

    alert('hiringFormAlert');
  });

  hiringForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const hiringFormData = new FormData(hiringForm),
          name = hiringFormData.get('name'),
          email = hiringFormData.get('email'),
          phone = hiringFormData.get('phone'),
          location = hiringFormData.get('location'),
          socialMedia = hiringFormData.get('social-media'),
          reCaptcha = hiringFormData.get('g-recaptcha-response');

    // Missing recaptcha
    if (reCaptcha == '') {
      alert(
        'hiringFormAlert',
        'reCAPTCHA is missing, try again!',
        'danger'
      );

      return;
    }

    for (const value of hiringFormData.values()) {
      console.log(value);
    }
  });
}